export const primary = '#00c4b3';
export const secondary = '#a43894';
export const accent = '#00c4b3';
export const muted = '#a43894';

export const white = '#FAFAFF';
export const black = '#0E131F';
export const teal = '#00c4b3';
export const purple = '#a43894';
export const mustard = '#f4bc46';
export const fuschia = '#e91d75';
export const red = '#ff3d3d';
export const lightGrey = '#A7A9AC';

export const text = '#0E131F';
export const background = '#FAFAFF';

export const blueSteel = '#3D4752';
