exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-afe-tsx": () => import("./../../../src/pages/afe.tsx" /* webpackChunkName: "component---src-pages-afe-tsx" */),
  "component---src-pages-codequests-tsx": () => import("./../../../src/pages/codequests.tsx" /* webpackChunkName: "component---src-pages-codequests-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-oops-tsx": () => import("./../../../src/pages/oops.tsx" /* webpackChunkName: "component---src-pages-oops-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-teacher-pre-registration-tsx": () => import("./../../../src/pages/teacher-pre-registration.tsx" /* webpackChunkName: "component---src-pages-teacher-pre-registration-tsx" */),
  "component---src-pages-teacher-submission-static-6-plus-tsx": () => import("./../../../src/pages/teacher-submission-static-6-plus.tsx" /* webpackChunkName: "component---src-pages-teacher-submission-static-6-plus-tsx" */),
  "component---src-pages-teacher-submission-static-tsx": () => import("./../../../src/pages/teacher-submission-static.tsx" /* webpackChunkName: "component---src-pages-teacher-submission-static-tsx" */),
  "component---src-pages-teacher-submission-tsx": () => import("./../../../src/pages/teacher-submission.tsx" /* webpackChunkName: "component---src-pages-teacher-submission-tsx" */),
  "component---src-pages-thanks-tsx": () => import("./../../../src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */),
  "component---src-pages-twelve-days-of-code-tsx": () => import("./../../../src/pages/twelve-days-of-code.tsx" /* webpackChunkName: "component---src-pages-twelve-days-of-code-tsx" */),
  "component---src-templates-article-hub-tsx": () => import("./../../../src/templates/article-hub.tsx" /* webpackChunkName: "component---src-templates-article-hub-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-curriculum-hub-tsx": () => import("./../../../src/templates/curriculum-hub.tsx" /* webpackChunkName: "component---src-templates-curriculum-hub-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-lesson-tsx": () => import("./../../../src/templates/lesson.tsx" /* webpackChunkName: "component---src-templates-lesson-tsx" */),
  "component---src-templates-team-tsx": () => import("./../../../src/templates/team.tsx" /* webpackChunkName: "component---src-templates-team-tsx" */)
}

